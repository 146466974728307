export const ROUTING_MAP = {
  anatomy: 'anatomy',
  articles: 'articles',
  personal: 'account',
  changePassword: 'change-password',
  bestAnatomyApp: 'best-anatomy-app',
  collection: {
    index: 'my-collection',
    articles: 'articles',
    models: 'models',
    organs: 'organs',
    organSystem: 'organ-system',
    pathologies: 'pathologies',
    types: 'types',
  },
  contactUs: 'contact-us',
  helpCenter: 'help-center',
  index: '/',
  models: 'models',
  noAccess: 'no-access',
  notFound: 'not-found',
  pathology: 'pathology',
  pricing: 'pricing',

  profile: {
    index: 'profile',
    changePassword: 'change-password',
    personal: 'account',
    subscription: 'subscriptions',
    theming: 'theming',
  },
  privacy: 'privacy-policy',
  serverError: 'server-error',
  termsOfUse: 'terms-of-use',
};
